/**
 * Swiper 
 * @url : https://swiperjs.com/api/
 */
// import Swiper JS
// core version + navigation, pagination, freemode & keyboard modules:
import Swiper, { Navigation, Pagination, FreeMode, Keyboard, EffectFade, Thumbs } from 'swiper';
/**
* Page Standard Swiper
* Default + freemode
*/
// Default
const psSwiper = Array.from(document.getElementsByClassName('js-swiper-page-standard'));

if (psSwiper.length) {
	for (const swiper of psSwiper) {
		// Swiper instance
		const psSwiperInstance = new Swiper(swiper, {
			modules: [Navigation, Pagination, Keyboard],
			slidesPerView: 'auto',
			loop: false,
			speed: 700,
			simulateTouch: false,
			navigation: {
				nextEl: swiper.querySelector('.swiper-button-next'),
				prevEl: swiper.querySelector('.swiper-button-prev'),
			},
			pagination: {
				el: swiper.querySelector('.swiper-pagination'),
				clickable: true
			},
			keyboard: true,
			init: false
		});

		// Init only if there is 2 or more slides
		const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'));

		if (nbSlides.length > 1) {
			psSwiperInstance.init();
		}
	}
}

// Produits
const psSwiperProducts = Array.from(document.getElementsByClassName('js-swiper-products'));

if (psSwiperProducts.length) {
	for (const swiper of psSwiperProducts) {
		// Swiper instance
		const psSwiperProductsInstance = new Swiper(swiper, {
			modules: [Navigation, Keyboard],
			slidesPerView: 'auto',
			loop: false,
			speed: 500,
			spaceBetween: 15,
			navigation: {
				nextEl: swiper.closest('.products-container').querySelector('.swiper-button-next'),
				prevEl: swiper.closest('.products-container').querySelector('.swiper-button-prev'),
			},
			// pagination: {
			// 	el: swiper.querySelector('.swiper-pagination'),
			// 	clickable: true
			// },
			keyboard: true,
			init: false,
			breakpoints: {
				320: { // when window width is >= 320px
					slidesPerView: 1,
					// spaceBetween: 20,
				},
				// 450: {
				// 	slidesPerView: 2,
				// 	// spaceBetween: 20,
				// },
				// 451: {
				// 	slidesPerView: 2,
				// 	spaceBetween: 15,
				// },
				600: {
					slidesPerView: 2,
				},
				// 780: {
				// 	slidesPerView: 2,
				// },
				939: {
					slidesPerView: 3,
				},
				1160: {
					slidesPerView: 4,
				},
				1300: {
					slidesPerView: 5,
				},
			}
		});

		// Init only if there is 1 or more slides
		const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'));

		if (nbSlides.length > 0) {
			psSwiperProductsInstance.init();
		}
	}
}

// Catégories
const psSwiperCategories = Array.from(document.getElementsByClassName('js-swiper-categories'));

if (psSwiperCategories.length) {
	for (const swiper of psSwiperCategories) {
		// Swiper instance
		const psSwiperCategoriesInstance = new Swiper(swiper, {
			modules: [Navigation, Keyboard],
			slidesPerView: 'auto',
			loop: false,
			speed: 500,
			spaceBetween: 15,
			navigation: {
				nextEl: swiper.closest('.categories-container').querySelector('.swiper-button-next'),
				prevEl: swiper.closest('.categories-container').querySelector('.swiper-button-prev'),
			},
			// pagination: {
			// 	el: swiper.querySelector('.swiper-pagination'),
			// 	clickable: true
			// },
			keyboard: true,
			init: false,
			breakpoints: {
				320: { // when window width is >= 320px
					slidesPerView: 2,
					spaceBetween: 20,
				},
				450: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				451: {
					slidesPerView: 3,
					spaceBetween: 15,
				},
				600: {
					slidesPerView: 4,
				},
				780: {
					slidesPerView: 5,
				},
				939: {
					slidesPerView: 7,
				},
				1300: {
					slidesPerView: 9,
				},
			}
		});

		// Init only if there is 2 or more slides
		const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'));

		if (nbSlides.length > 1) {
			psSwiperCategoriesInstance.init();
		}
	}
}

// Sous-catégories
const psSwiperSubCategories = Array.from(document.getElementsByClassName('js-swiper-subcategories'));

if (psSwiperSubCategories.length) {
	for (const swiper of psSwiperSubCategories) {
		// Swiper instance
		const psSwiperSubCategoriesInstance = new Swiper(swiper, {
			// modules: [Navigation, Keyboard],
			slidesPerView: 'auto',
			loop: false,
			grabCursor: true,
			// speed: 200,
			spaceBetween: 10,
			// navigation: {
			// 	nextEl: swiper.closest('.categories-container').querySelector('.swiper-button-next'),
			// 	prevEl: swiper.closest('.categories-container').querySelector('.swiper-button-prev'),
			// },
			// pagination: {
			// 	el: swiper.querySelector('.swiper-pagination'),
			// 	clickable: true
			// },
			keyboard: false,
			init: false,
			// freeMode: true,
			// freeMode: {
			// 	enabled: true,
			// 	sticky: true,
			// 	// momentumBounce: false,
			// },
			breakpoints: {
				320: { // when window width is >= 320px
					spaceBetween: 8,
				},
				600: {
					spaceBetween: 10,
				},
			}
		});

		// Init only if there is 2 or more slides
		const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'));

		if (nbSlides.length > 1) {
			psSwiperSubCategoriesInstance.init();
		}
	}
}

// Freemode
const swiperFreemode = Array.from(document.getElementsByClassName('js-swiper-freemode'));
if (swiperFreemode.length) {
	for (const swiper of swiperFreemode) {
		// Swiper instance
		const swiperFreeModeInstance = new Swiper(swiper, {
			observer: true,
			modules: [Navigation, Pagination, FreeMode, Keyboard],
			slidesPerView: 'auto',
			centeredSlides: false,
			grabCursor: true,
			freeMode: true,
			speed: 700,
			navigation: {
				nextEl: swiper.querySelector('.swiper-button-next'),
				prevEl: swiper.querySelector('.swiper-button-prev'),
			},
			keyboard: true,
			init: false
		});

		// Init only if there is 2 or more slides
		const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'));

		if (nbSlides.length > 1) {
			swiperFreeModeInstance.init();
		}
	}
}

// 1 by 1
const swiperOnebyone = Array.from(document.getElementsByClassName('js-swiper-onebyone'));
if (swiperOnebyone.length) {
	for (const swiper of swiperOnebyone) {
		// Swiper instance
		const swiperOnebyoneInstance = new Swiper(swiper, {
			modules: [Navigation, Keyboard, EffectFade],
			// effect: 'fade',
			// fadeEffect: {
			//   crossFade: true
			// },
			// loop: true,
			slidesPerView: 1,
			spaceBetween: 30,
			navigation: {
				nextEl: swiper.querySelector('.swiper-button-next'),
				prevEl: swiper.querySelector('.swiper-button-prev'),
			},
			keyboard: true,
			init: false
		});

		// Init only if there is 2 or more slides
		const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'));

		if (nbSlides.length > 1) {
			swiperOnebyoneInstance.init();
		}
	}
}

// Héros
const swiper2Desktop1Mobile = Array.from(document.getElementsByClassName('js-swiper-2-desktop-1-mobile'));
if (swiper2Desktop1Mobile.length) {
	for (const swiper of swiper2Desktop1Mobile) {
		// Swiper instance
		const swiper2Desktop1MobileInstance = new Swiper(swiper, {
			modules: [Navigation, Keyboard],
			loop: false,
      		speed: 500,
			navigation: {
				nextEl: swiper.querySelector('.swiper-button-next'),
				prevEl: swiper.querySelector('.swiper-button-prev'),
			},
			keyboard: true,
			init: false,
			breakpoints: {
				320: { // when window width is >= 320px
					slidesPerView: 1,
					spaceBetween: 20,
				},
				939: { // when window width is >= 480px
					slidesPerView: 2,
					spaceBetween: 32,
					// initialSlide: 0,
				},
			}
		});

		// Init only if there is 2 or more slides
		const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'));

		if (nbSlides.length > 1) {
			swiper2Desktop1MobileInstance.init();
		}
	}
}
